import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { driveOperationalImprovements } from "../../../data/landing/gated-v2";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import { NewBusinessTypes } from "../../../data/common-data";
import { zipValidationRegExp } from "../../../constants";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedCTA = loadable(() =>
  import("../../../components/CustomGated/GatedCTA")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["name", "email", "phone", "company", "get_demo"];
const shortFormDataToSend = ["name", "email", "phone", "company", "get_demo"];
const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.DROPDOWN,
    itemSubType: null,
    label: "Industry",
    name: "industry",
    rules: [
      {
        required: true,
        message: "Please enter the type of the project",
      },
    ],
    placeholder: "Select your industry",
    options: NewBusinessTypes,
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv2 = () => {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Gated Assets | Using Online Reviews to Drive Operational Improvements "
        description="Download your free online review playbook and learn how you can save time and improve operations at your restaurant by leveraging real guest feedback."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row className="gate-heading-v1" gutter={[32, 32]}>
        <Col
          lg={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              Using online reviews to drive operational improvements
            </Heading>
            <p className="gated-intro-para cta-light">
              See how simple it is to use online reviews of your restaurant to
              catch wind of operational issues at your restaurant—and fix them
              before they become a serious problem. From staff performance to
              online ordering, wait times, and how your food tastes, get honest
              with your operations by putting your online reviews to work.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              formTitle="Download your free playbook"
              formItems={data}
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formDataToSend={formDataToSend}
              shortFormDataToSend={shortFormDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free copy today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/using-online-reviews-to-drive-operational-improvements/thank-you"
              gatedCampaign="manager-toolkit"
              withLineOfBusinessOption
            />
          </div>

          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image className="banner-text" imageName="banner-text.png" />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial sectionData={driveOperationalImprovements} />
        </Col>

        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="Use free guest feedback to make operational, revenue-boosting changes in your restaurant"
            mark="."
            subtext="Stop dreading customer feedback on review sites, and start using it to your advantage. In this guide, you’ll learn how to reframe customer feedback from a criticism to a meaningful and welcome opportunity."
          />
        </Col>
      </Row>

      <Row
        gutter={[32, 32]}
        className="mt-10 lg:mt-20 mb-20 lg:mb-32 px-4 lg:px-0"
      >
        <Col xs={24}>
          <Image imageName="drive-operational-improvements-b.png" />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv2;
